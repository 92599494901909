"use client";

import { useEffect } from "react";
import { useRouter } from "next/navigation";
import { RootState } from "@/store";
import { useSelector } from "react-redux";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  
  const isLoggedIn = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  useEffect(() => {
    if (!isLoggedIn) {
      router.push("/auth/login");
    }
  }, [isLoggedIn, router]);

  return <>{children}</>;
}
