"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Icons } from "../icons";

const LINKS = [
  // {
  //   route: "/dashboard",
  //   name: "Dashboard",
  //   icon: Icons.Home,
  // },
  { route: "/assets", name: "Asset Library", icon: Icons.Folder },
  { route: "/assets/upload", name: "Upload Assets", icon: Icons.CloudUpload },
];

export const Sidenav = () => {
  const [activeRoute, setActiveRoute] = useState(LINKS[0]);
  const pathname = usePathname();

  useEffect(() => {
    const matchingRoute = LINKS.find((link) => pathname === link.route);

    if (matchingRoute) {
      setActiveRoute(matchingRoute);
    } else {
      setActiveRoute({ route: "", name: "", icon: Icons.Home });
    }
  }, [pathname]);

  const handleNavigate = (link: any) => {
    setActiveRoute(link);
  };

  if (pathname.includes("/toolkit") || pathname.includes("/auth")) {
    return null;
  }

  return (
    <nav className="w-[250px] flex flex-col gap-4 bg-gray-800 text-white p-4 max-h-[calc(100vh-80px)] overflow-y-auto">
      {LINKS.map((link) => (
        <Link href={link.route} key={link.route} passHref>
          <div
            className={`p-2 cursor-pointer text-sm font-semibold rounded-lg ${
              activeRoute.route === link.route
                ? "text-black bg-blue-100"
                : "bg-transparent"
            } hover:bg-blue-100 hover:text-black`}
            onClick={() => handleNavigate(link)}
          >
            <div className="flex items-center gap-2">
              <link.icon
                fill={`${activeRoute.route === link.route ? "black" : "white"}`}
              />
              <span>{link.name}</span>
            </div>
          </div>
        </Link>
      ))}
    </nav>
  );
};
