"use client";

import React from "react";
import { Provider } from "react-redux";
import store, { persistor } from "@/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster as Shadtoaster } from "@/components/third-party/shadcn/toaster";
import { ThemeProvider } from "@/components/shared/theme.provider";
import { TooltipProvider } from "@/components/third-party/shadcn/tooltip";
import { AuthProvider } from "@/components/shared/auth.provider";
import { ChakraProvider } from "@chakra-ui/react";
import { BackgroundProvider } from "@/components/shared/background.provider";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Shadtoaster />
        <ThemeProvider
          attribute="class"
          defaultTheme="system"
          enableSystem
          disableTransitionOnChange
        >
          <TooltipProvider>
            <ChakraProvider>
              <BackgroundProvider>
                <AuthProvider>{children}</AuthProvider>
              </BackgroundProvider>
            </ChakraProvider>
          </TooltipProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
