"use client";

import { useRouter } from "next/navigation";
import { RootState, closeBackgroundMessage } from "@/store";
import { useDispatch, useSelector } from "react-redux";

export function BackgroundProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const { show, message, closable } = useSelector(
    (state: RootState) => state.background
  );

  const handleClose = () => {
    dispatch(closeBackgroundMessage());
  };

  return (
    <>
      {show && (
        <div className="fixed rounded-lg left-[48px] bottom-10 border-4 border-[white] z-9999 transform bg-white text-white px-4 py-2 shadow-lg">
          <div className="flex justify-between items-center text-black">
            <span>{message}</span>
            {closable && (
              <button onClick={handleClose} className="ml-4">
                &times;
              </button>
            )}
          </div>
        </div>
      )}
      {children}
    </>
  );
}
